export const hex = {
  parsley: '#183C34',
  darkParsley: '#718f94',
  offWhite: '#EAEBED',
};

export const colors = {
  home: hex.parsley,
  accent: hex.darkParsley,
  text: {
    regular: hex.offWhite,
    opaque: hex.darkParsley,
  },
};
