import styled from 'styled-components';

import { colors } from '../styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  iframe {
    height: calc(100vh);
    width: calc(100vw);
    margin: 2rem;
    box-sizing: border-box;
    background-color: ${colors.home};
  }
`;

const ShowerRSVP = () => {
  return (
    <Wrapper>
      <iframe
        title="Shower RSVP"
        src="https://docs.google.com/forms/d/e/1FAIpQLSdZYkO8x64o1Cieja0dMWN0ZrP0Jrwx3AhfC-1iEBbBbfagAg/viewform?embedded=true"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      >
        Loading…
      </iframe>
    </Wrapper>
  );
};

export default ShowerRSVP;
